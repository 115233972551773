<template>
  <LeadStatusesPage
    title="Status concluído"
    description="Status que definem uma lead como concluída"
    param-id="lead_statuses_completed"
  />
</template>

<script>
import LeadStatusesPage from "@/components/Lead/Statuses/LeadStatusesPage.vue";

export default {
  components: {
    LeadStatusesPage,
  },
};
</script>
