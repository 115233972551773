import Vue from "vue";

export async function getParam(param_id) {
  const response = await Vue.prototype.$http.get(`system_params/${param_id}`);

  return response.data;
}

export async function updateParam(param_id, value) {
  const response = await Vue.prototype.$http.put(`system_params/${param_id}`, {
    value,
  });

  return response.data;
}
