<template>
  <div>
    <v-row>
      <v-col>
        <v-card
          flat
          color="#f1f1f1"
          style="max-width: 600px"
          :loading="loading"
          :disabled="loading"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ title }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="pt-5">
            <div class="text-right">
              <v-chip v-if="updating" color="info" dark small outlined label>
                <v-progress-circular
                  indeterminate
                  color="info"
                  size="15"
                  class="mr-2 py-2"
                  :width="2"
                ></v-progress-circular>
                A salvar
              </v-chip>

              <v-chip
                @click="updateParam(selected_statuses)"
                v-else-if="error == true"
                color="error"
                dark
                small
                outlined
                label
              >
                <v-icon small left>mdi-close</v-icon>
                Erro, tentar novamente
              </v-chip>
              <v-chip v-else color="green" dark small outlined label>
                <v-icon small left>mdi-check</v-icon>
                Salvo
              </v-chip>
            </div>

            <v-list class="mt-3">
              <div v-for="status in lead_statuses" :key="status.id">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon left :color="status.color" small>
                        mdi-circle
                      </v-icon>
                      {{ status.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="selected_statuses"
                      :value="status.id"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-list>
            <v-col class="text-right">
              <v-btn color="primary" @click="saveSelections">Salvar</v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbarSuccess" color="success">
      Alterações salvas com sucesso!
      <v-btn color="white" text @click="snackbarSuccess = false">Fechar</v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbarError" color="error">
      Ocorreu um erro ao salvar as alterações. Por favor, tente novamente.
      <v-btn color="white" text @click="snackbarError = false">Fechar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { getParam, updateParam } from "@/services/configs/system_params";

export default {
  props: {
    title: String,
    description: String,
    paramId: String,
  },

  data() {
    return {
      selected_statuses: [],
      loading: false,
      updating: false,
      error: false,
      snackbarSuccess: false,
      snackbarError: false,
    };
  },

  methods: {
    async getLeadStatusesActiveForUnityParam() {
      this.loading = true;
      try {
        const response = await getParam(this.paramId);
        this.selected_statuses = response.value;
      } finally {
        this.loading = false;
      }
    },
    async updateParam(value) {
      this.error = false;
      this.updating = true;
      try {
        await updateParam(this.paramId, value);
        this.snackbarSuccess = true;
      } catch (error) {
        this.error = true;
        this.snackbarError = true;
      } finally {
        this.updating = false;
      }
    },
    saveSelections() {
      this.updateParam(this.selected_statuses);
    },
  },

  computed: {
    lead_statuses() {
      return this.$store.getters["lead_statuses/getLeadStatuses"];
    },
  },

  created() {
    this.$store.dispatch("lead_statuses/loadStatuses");
    this.getLeadStatusesActiveForUnityParam();
  },
};
</script>
